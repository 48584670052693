import * as React from "react"
import { Link as Lonk, graphql, SetFieldsOnGraphQLNodeTypeArgs } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Helmet from "react-helmet"
import Img from "gatsby-image"

const IndexPage = ({ data }: any) => {
  React.useEffect(() => {
    console.log(data)
  })
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="g0gRqpcMrj7gn4hJm0cfIo1dYu1XsZ_ojkxXZ25e0yQ"
        />
      </Helmet>
      <Layout>
        <SEO title="Home" />
        <h1>Our Projects</h1>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Lonk to="/beecon" style={{ textDecoration: "none" }}>
            <Link href="/beecon">
              <div className="name">
                <h2>Beecon</h2>
              </div>
              <div className="content">
                <Img
                  alt="Beecon.app Website"
                  fluid={data.beeconLogo.childImageSharp.fluid}
                />
              </div>
            </Link>
          </Lonk>
          <Link target="_blank" href="http://moosen.im:3010">
            <div className="name">
              <h2>Plains Dedicated</h2>
            </div>
            <div className="content">
              <Img
                alt="Plains Dedicated Website"
                fluid={data.plainsLogo.childImageSharp.fluid}
              />
            </div>
          </Link>
          <Link target="_blank" href="http://mountainmarketgl.com">
            <div className="name">
              <h2>Mountain Market Grand Lake</h2>
            </div>
            <div className="content">
              <Img
                alt="Mountain Market Website"
                fluid={data.mmglLogo.childImageSharp.fluid}
              />
            </div>
          </Link>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    beeconLogo: file(relativePath: { eq: "beecon7.bluegrey.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plainsLogo: file(relativePath: { eq: "plains-snapshot.PNG" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mmglLogo: file(relativePath: { eq: "mmgl-snapshot.PNG" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Link = styled.a`
  text-decoration: none;
  width: 100%;
  margin: 5px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .name {
    padding: 10px;
    color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: all 1s;
    width: 100%;
    text-align: left;
    justify-content: center;
    h2 {
      margin: 0px 0px 0px 0px;
    }
  }
  .content {
    width: 100%;
    opacity: 0;
    transition: all 1s;
    height: 0px;
    overflow: hidden;
  }
  :hover .name {
    color: white;
    background-color: goldenrod;
    font-size: 3em;
  }
  :hover .content {
    opacity: 1;
    height: 300px;
  }
`

export default IndexPage
